import { Box, styled, experimental_sx as sx } from "@mui/material"
import logo from "../logo.svg"
import "./Logo.css"

const Wrapper = styled(Box)(sx({
    display: "flex"
    }
))
const LogoName = styled('span', { name: "Logo-Name" })(sx({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "1.4em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const StraclouLogo = () => {
    return (
        <Wrapper>
            <img src={logo} width="25px" color={"#fff"} alt="Straclou logo" />
            <LogoName className="logo_name">Straclou</LogoName>
        </Wrapper>
    )
}

export default StraclouLogo