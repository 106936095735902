import { styled, experimental_sx as sx, Box, SxProps} from "@mui/material"
import { ReactNode, useEffect, useState } from "react"


// Custom Style Variant State
const STATE: any  = {
    fullscreen:{
        width: "100vw",
        height: "100vh",
        overflow: "hidden"
    },
    auto: {
        width: "auto",
        height: "auto"
    },
    fullwidth: {
        width: "100%",
        height: "auto"
    },
    quarterWidth: {
        width: "85%",
        height: "auto",
        margin: "0 auto"
    },
    halfWidth: {
        width: "50vw",
        height: "auto",
    },

}

// Custom styles Arguments
interface IWrapperProps{
    state?: any
}
//Custom component styles
const Wrapper = styled(Box, {name: "Container"})<IWrapperProps>(({state})=>sx({
    ...state,
    
}))




// Type Var of the component size
type Size = "fullscreen" | "fullWidth" | "auto" | "quarterWidth" | "halfWidth"

// Component IProps 
interface IProps{
    children?: ReactNode
    variant?: Size;
    sx?: SxProps
}
const Container: React.FC<IProps> = ({children, variant, sx})=>{
    const [v, setV] = useState<any>(STATE["auto"])   

    useEffect(()=>{
        if(variant) setV(STATE[variant])
        
        // return ()=>{console.log("Complete action and clean up code.")}
    },[variant])

    return (
    <Wrapper state={v} sx={sx}>
        {children}
    </Wrapper>
    )
}

export default Container