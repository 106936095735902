import { styled, experimental_sx as sx, Button, Grid, IconButton, alpha, Box, Slide, Dialog } from "@mui/material"
import { useNavigate } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import StraclouLogo from "../../components/StraclouLogo"
import Typography from "../../components/Typography";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const NavigationMenu = styled(Box, { name: "Navigation-Menu" })(sx({
    position: "relative",
    display: "flex",
    alignItems: "center",
    margin: "0 0 24px 0",
    padding: "24px",
    color: "#000"
    // background: "red"
}))

const Logo = styled(Box, { name: "Navigation-Logo" })(sx({
    display: "flex",
    padding: "0 24px",
    cursor: "pointer",
    "@media (max-width: 750px)": {
        padding: 0
    }
}))

const Menu = styled(Box, { name: "Menu" })(sx({
    display: "flex",
    position: "absolute",
    right: "64px",
    top: "20px",
    "@media (max-width: 750px)": {
        display: "none"
    }
}))


const MobileMenu = styled(Box, { name: "Mobile-Menu" })(({ theme }) => sx({
    position: "absolute",
    right: "24px",
    top: "24px",
    display: "none",
    "@media (max-width: 750px)": {
        display: "block"
    },
    "& svg": {
        color: "#242424"
    }
}))


const ActionButton = styled(Button)(sx({

}))

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const PageHeader = () => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <NavigationMenu >
            <Logo onClick={() => { navigate('/') }}>
                <StraclouLogo />
            </Logo>

            <Menu>
                <Typography family="Palanquin">
                    <span style={{ margin: "0 16px", cursor: "pointer", fontSize: "14pt" }} onClick={() => { navigate("/signin") }} >Log In</span>
                </Typography>
                <ActionButton sx={{ width: "120px", fontSize: "14pt" }} variant="contained" color="secondary">Sign up</ActionButton>
            </Menu>
            <MobileMenu>
                <IconButton onClick={handleClickOpen}>
                    <MenuIcon />
                </IconButton>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    fullScreen
                    aria-describedby="alert-dialog-slide-description"
                >
                    <Box sx={{ padding: "16px", display: "flex", justifyContent: "space-between" }}>
                        <StraclouLogo />
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{width: "80%", margin:"0 auto", display: "flex", flexDirection: "column"}}>

                        <Box sx={{height: "50px"}}/>
                        <Button variant="contained">Sign up</Button>
                        <Box sx={{height: "30px"}}/>
                        <Button variant="outlined">Login</Button>
                    </Box>

                </Dialog>

            </MobileMenu>
        </NavigationMenu>
    )
}

export default PageHeader