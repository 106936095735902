import { styled, experimental_sx as sx } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import Typography from "../../components/Typography"

type Swing = "row" | "row-reverse"
interface IProps {
    data?: any
    position?: "odd" | "even"
}

const Feature = styled(Box)<{ swing: Swing }>(({ swing }) => sx({
    width: "90%",
    height: "100vh",
    margin: "0px auto",
    padding: "0px",
    // background: grey[100],
    transition: "all 500ms ease-in-out",
    fontFamily: `'Montserrat', sans-serif`,
    display: "flex",
    // gridTemplateColumns: "1fr 1fr",
    justifyContent: "space-evenly",
    flexDirection: swing,

    "&:hover": {
        // transform: "scale(1.1)",
        // boxShadow: "2px 7px 26px 1px rgba(0,0,0,0.12)",
        // "-webkit-box-shadow": "2px 7px 26px 1px rgba(0,0,0,0.12)"
    },

    "@media (max-width: 750px)": {
        // minWidth: "250px",
        width: "100%",
        flexDirection: "column",
        height: "auto",
        "&:hover": {
            transform: "scale(1)",
            boxShadow: "none"
        }

    },


}))
const IconImg = styled(Box)(({ theme }) => sx({
    // background: red[600],
    height: "150px",
    width: "150px",
    borderRadius: "5%",
    // border: `5px solid ${theme.palette.secondary.main}`
}))
const Title = styled(Box)(sx({
    // background: grey[200],
    fontSize: "2.2em",
    marginTop: "16px",
    fontWeight: 600
}))
const SubTitle = styled(Box)(sx({
    // background: grey[200],
    fontSize: "1.44em",
    margin: "0 0 16px 0",
    color: grey[600]
}))
const Content = styled(Box)(sx({
    // background: grey[200],
    fontSize: "1.4em",
    lineHeight: "1.6em"
}))


const FeatureItem: React.FC<IProps> = ({ data, position }) => {
    const [swing, setSwing] = useState<Swing>("row")

    useEffect(() => {
        if (position && position === "odd") setSwing("row-reverse"); else setSwing("row")
    }, [position])
    return (
        <Feature swing={swing}>
            <Box sx={{ display: "flex", width: "100%", background: "0", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ display: "flex", }}>
                    <Box sx={{
                        width: "300px",
                        height: "300px",
                        borderRadius: "50px",
                        background: "#f3f1f5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        "@media (max-width: 750px)": {
                            width: "200px",
                            height: "200px",
                        }

                    }}>
                        <IconImg sx={{ backgroundImage: `url(${data.icon})`, backgroundSize: "cover" }} />
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                background: "0", height: "auto", width: "100%", display: "flex", justifyContent: "center", alignItems: "center",
                "@media (max-width: 750px)": {
                    padding: "50px 0"
                }
            }}>
                <Box >
                    <Title sx={{
                        "@media (max-width: 750px)": {
                            fontSize: "22pt"
                        }
                    }}>{data.title}</Title>
                    <SubTitle sx={{
                        "@media (max-width: 750px)": {
                            fontSize: "16pt"
                        }
                    }}>{data.subTitle}</SubTitle>
                    <Content>
                        <Typography family="Palanquin" sx={{
                            "@media (max-width: 750px)": {
                                fontSize: "15pt"
                            }
                        }}>
                            {data?.content}
                        </Typography>
                    </Content>
                </Box>
            </Box>
        </Feature >
    )
}

export default FeatureItem