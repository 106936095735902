import { styled, Box, experimental_sx as sx, Stack } from "@mui/material"
import logo from "../../logo.svg"
import SignInForm from "./SignInForm"
import { useNavigate } from "react-router-dom"

const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(sx({
    width: "100vw",
    height: "100vh",
    ...CenterItem
}))

const Content = styled(Box)(sx({
    minWidth: "60%",
    ...CenterItem
}))

const Logo = styled(Box)(sx({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(sx({
    ...CenterItem,
    fontSize: "2.9em",
    fontWeight: 900
}))

const LogoName = styled('span')(sx({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const SignInPage = () => {

    const navigate = useNavigate()
    return (
        <Wrapper>
            <Content>
                <Stack gap={1}>
                    <Logo onClick={()=>{navigate('/')}}>
                        <img src={logo} width="35px" color={"#fff"} alt="Straclou logo"/>
                        <LogoName>Straclou</LogoName>
                    </Logo>
                    <Instruction>Sign in to Straclou</Instruction>
                    <Box sx={{ ...CenterItem, margin: "16px 0 0 0", fontSize: "1.2em" }}>We suggest using the community email address.</Box>
                    <Box>
                        <SignInForm />
                    </Box>
                    <Box sx={{ height: "auto", ...CenterItem }}>Footer notes</Box>
                </Stack>
                    <Box sx={{ textAlign: "center", fontSize: ".9em", fontWeight: 400, position: "absolute", bottom: "10px" }}>Powered by Knymbus</Box>
            </Content>
        </Wrapper>
    )
}

export default SignInPage