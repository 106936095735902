import { styled, experimental_sx as sx, Button, Grid, IconButton, alpha } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { theme } from "../../globals/theme"
import { grey } from "@mui/material/colors"
import FeatureSection from "./FeatureSection"
import RethinkBanner from "./RethinkBanner"
import RequestDemoComponent from "./RequestDemoComponent"
import Footer from "./Footer"
import { useNavigate } from "react-router-dom"
import bg1 from '../../images/hpimg.png'
import bg2 from '../../images/hpimg2.png'


import Container from "../../components/Container"
import Typography from "../../components/Typography"
import PageHeader from "./PageHeader"
import FeatureBenefits from "./FeatureBenefits"
import { isMobile } from 'react-device-detect'


const PageWrapper = styled(Box)(sx({
    color: "#fff",

}))
const NavigationHeader = styled(Box, { name: "Navigation-Header" })(({ theme }) => sx({
    height: "30px",
    // background: theme.palette.primary.main,

    fontSize: ".9em",
    color: theme.palette.common.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `2px solid ${alpha(theme.palette.secondary.main, .5)}`
}))



const MainBanner = styled(Box, { name: "Main-Banner" })(sx({
    position: "relative",
    height: "100%",
    // background: "#143691",
    // backgroundImage: `url(${gate})`,
    // backgroundSize: "cover",


}))

const BannerCover = styled(Box, { name: "Banner-Cover" })(({ theme }) => sx({
    position: "absolute",
    background: theme.palette.common.white,
    height: "100%",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,

    zIndex: "1",
    // opacity: ".8",
}))

const BannerContent = styled(Box, { name: "Banner-Content" })(sx({
    zIndex: "2",
    position: "relative"
}))

const ActionButton = styled(Button)(sx({

}))

const Tag = styled('span')(sx({
    color: theme.palette.secondary.dark,
    fontWeight: 700
}))


const HomePage: React.FC = () => {
    const navigate = useNavigate()
    return (
        <PageWrapper>
            <NavigationHeader >
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ fontFamily: `'Fira Sans', sans-serif`, marginRight: "16px", fontWeight: 500, fontSize: "1.15em" }}>Try free for 30 days</Box>
                    <Box sx={{ color: theme.palette.secondary.main, cursor: "pointer", "&:hover": { t6xtDecoration: "underline" } }}>
                        <a href="learn_more_demo">Learn More</a>
                    </Box>
                </Box>
            </NavigationHeader>
            <Container variant={isMobile ? "auto" : "fullscreen"} sx={{
                "@media (max-width: 750px)": {
                    height: "auto",
                    paddingBottom: "110px"
                },
            }}>



                <MainBanner>
                    <BannerCover />

                    <BannerContent>
                        <PageHeader />
                        <Box sx={{ width: isMobile ? "98%" : "80%", margin: isMobile ? "0 auto" : "50px  auto" }}>


                            <Grid container spacing={1} sx={{ bgcolor: "0" }}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        family="Fira Sans Condensed"
                                        fontWeight={500}
                                        heading={2}
                                        sx={{
                                            // lineHeight: "50px",
                                            color: theme.palette.secondary.main,
                                            letterSpacing: "1px",
                                            padding: "24px 0 0 24px",
                                            
                                            "@media (max-width: 750px)": {
                                                fontSize: '32pt'
                                            },
                                        }}

                                    >
                                        <span style={{ color: "#000", }}>Breakthrough the mundane &</span> experience something new!
                                    </Typography>
                                    <Typography
                                        family="Palanquin"

                                        sx={{
                                            width: "100%",
                                            lineHeight: "2em",
                                            padding: "0 24px",
                                            color: "#000",
                                            margin: "16px 0 ",
                                            fontSize: "14pt",
                                            "@media (max-width: 750px)": {
                                                fontSize: '12pt',
                                                width: "80%",
                                                marginBottom: '30px'
                                            },
                                        }}>
                                        Future proof community management with Straclou that connects properties and residents in a cohesive manner.
                                    </Typography>
                                    <Box sx={{ padding: "0 0 0 24px" }}>
                                        <ActionButton sx={{ width: "200px", fontSize: "1.2em", fontFamily: `'Fira Sans', sans-serif`, fontWeight: 200 }} variant={"contained"} color="secondary">Get Started</ActionButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ position: "relative", background: "0", height: "500px" }}>
                                        <Box sx={{ position: "absolute", left: "-110px" }}>
                                            <img src={bg1} width={"780px"} alt="Gated community SeaWind" />
                                        </Box>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </BannerContent>
                </MainBanner>

            </Container>

            <Container variant="fullscreen" sx={{
                position: "relative",
                "@media (max-width: 750px)": {
                    height: "auto"
                }
            }}>

                <Box sx={{ position: "relative", background: "#f3f1f5", height: "140vh", display: "flex", justifyContent: "center", alignItems: "flex-start", color: "#333" }}>

                    <Box sx={{
                        fontWeight: 200,
                        fontFamily: `'Fira Sans', sans-serif`,
                        minWidth: "60%",
                        width: "70%",
                        textAlign: "left",
                        fontSize: "1.7em",
                        lineHeight: "2em",
                        marginTop: "150px",
                        "@media (max-width: 750px)": {
                            fontSize: '15pt',
                            marginTop: "50px",
                            width: "90%"

                        },
                    }}>

                        <Typography
                            family="Fira Sans Condensed"
                            fontWeight={500}
                            heading={4}
                            sx={{
                                lineHeight: "1.5em"
                            }}>
                            Carry out Simple Administrative Processes with Straclou
                        </Typography>

                        <Typography family="Palanquin">
                            This web app is a subscription based community management software that is
                            intuitively designed to <Tag>automate</Tag> and modernize your
                            day to day processes. It will help to <Tag>transform</Tag>,
                            <Tag> streamline</Tag> and <Tag>organize</Tag> information in a
                            focused manner that brings clarity to enable better <Tag>decision making</Tag>.
                        </Typography>
                    </Box>

                </Box>
                <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, height: "150vh" }}>
                    <Box sx={{
                        position: "absolute",
                        bottom: "-190px",
                        left: "-181px",
                        "@media (max-width: 750px)": {
                            bottom: 0
                        }
                    }}>
                        <img src={bg2} width={"780px"} alt="Organized business" />
                    </Box>
                </Box>

            </Container>




            {/* Section 3 */}

            <Container variant="fullWidth" sx={{
                "@media (max-width: 750px)": {
                    height: "auto"
                }
            }}>
                <Box sx={{ overflow: "hidden", background: "#fff", height: "auto", color: "#333" }}>

                    <Box sx={{
                        height: "80%", overflow: "hidden", marginTop: "350px",
                        "@media (max-width: 750px)": {
                            marginTop: "20px"
                        }
                    }}>

                        <Box sx={{
                            fontSize: "2.7em", fontWeight: 200,
                            display: "flex", justifyContent: "center",
                            width: isMobile ? "85%": "100%",
                            margin: "100px 0 0 0",
                            "@media (max-width: 750px)": {
                                fontSize: '20pt',
                                margin: "50px 0 0 0",
                                

                            }
                        }}>
                            <Typography
                                family="Fira Sans Condensed"
                                fontWeight={500}
                                sx={{ fontSize: "1.3em", textAlign: "center" }}
                            >
                                GET STARTED WITH STRACLOU
                            </Typography>
                        </Box>
                        <Box sx={{ fontSize: "1.7em", fontWeight: 100, display: "flex", justifyContent: "center", margin: "16px 0 0 0" }}>
                            <Typography
                                family="Palanquin"
                                fontWeight={500}
                                sx={{ fontSize: "calc(2em * .75)" }}
                            >
                                Basic Features
                            </Typography>
                        </Box>
                        <FeatureSection />

                    </Box>
                </Box>
            </Container>




            {/* Section 4 */}
            <Container variant="auto">
                <FeatureBenefits />
            </Container>

            <Container>
                <Box sx={{ background: "#083872", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <Box sx={{
                        minWidth: "90%", width: "60%",
                        "@media (max-width: 750px)": {
                            width: "98%"
                        }
                    }}>
                        <RequestDemoComponent />
                    </Box>
                </Box>
            </Container>

            {/* Footer */}
            <Container>
                <Footer />
            </Container>

        </PageWrapper >
    )
}

export default HomePage