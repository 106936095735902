import { Box, styled } from "@mui/material"
import Typography from "../../components/Typography"
import FeatureBenefitsItem from "./FeatureBenefitsItem"
import { v4 as uuidV4} from 'uuid'

import fbbg1 from '../../images/fbicons/contact.jpg'
import cycle from '../../images/fbicons/cycle.svg'
import watcher from '../../images/fbicons/eye.svg'
import easy_to_use from '../../images/fbicons/easy.svg'
import productivity from '../../images/fbicons/productivity.svg'

const Content = styled(Box)({
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 750px)": {
        flexDirection: "column",
    }
})


const BENEFITS = [
    {
        key: uuidV4(),
        title: "Productivity & Efficiency",
        icon: productivity,
        content: `With continued used of Straclou, it will help 
        you to operate your community with excellent organizational efficiency. 
        Straclou enables users to quickly find information to carry out 
        transactions with your residents in a timely manner.`
    },
    {
        key: uuidV4(),
        title: "Continuity",
        icon: cycle,        
        content: `Straclou is designed to bring continuity to community management. 
        The application ensures this by providing past and present data which help 
        new board members to be aware of the financial position of the community 
        when they take over.`
    },
    {
        key: uuidV4(),
        title: "Ease of Use",
        icon: easy_to_use,
        content: `Users will be able to learn the system quickly due to 
        its minimalistic design and simple screens. This gives the user
         better control over the use of the system and helps with the 
         management of properties and its associated processes.`
    },
    {
        key: uuidV4(),
        title: "Accountability & Integrity",
        icon: watcher,
        content: `Straclou offers transparency and accountability through data modification
         tracking. This allows users to track any modifications or submissions resulting
          in more accountability and transparency over who made changes to the 
          information stored in Straclou.`
    },

]


const FeatureBenefits = () => {
    return (
        <Box sx={{position: "relative", height: "auto", background: "#f3f1f5", color: "#000", padding: "0 0 90px 0" }}>
            <Box sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: "red",
                        backgroundImage: `url(${fbbg1})`,
                        backgroundSize: "cover",
                        opacity: .05
                    }}/>
            <Content>
                <Box sx={{ width: "40%", height: "700px", background: "0", 
                display: "flex", justifyContent: "center", alignItems: "center",
                "@media (max-width: 750px)": {
                    width: "90%",
                    height: "290px",
                    margin: "0 auto"
                }
                }}>

                    <Box sx={{width: "380px"}}>
                        <Typography heading={7} color="primary">Why Straclou?</Typography>
                        <Typography family="Palanquin" sx={{fontSize: "1.2em"}}>
                            Straclou is an integrated community management platform that
                            automates everyday tasks, simplifies management to let
                            you meet the needs of your residents.
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ width: "auto", padding: "48px 8px", background: "0", 
                display: "grid", gridTemplateColumns:"1fr 1fr", gap:"10px 20px",
                "@media (max-width: 750px)": {
                    gridTemplateColumns:"1fr",
                    gap: "40px 0px"
                }
                 }}>
                    
                    {
                        BENEFITS.map(el=>(
                            <FeatureBenefitsItem key={el.key} item={el}/>
                        ))
                    }
                </Box>
            </Content>
        </Box>
    )
}

export default FeatureBenefits