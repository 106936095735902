import { createTheme } from "@mui/material/styles"
import createPalette from "@mui/material/styles/createPalette"
import { Theme } from "@mui/system"

export const theme: Theme = createTheme({
    palette: createPalette({
        mode: "light",
        primary: {
            // main: "#04067c",
            main: "#083872",
            // main: "#142C6C",
            light: "#050794", // original
            // light: "#004E90",
            dark:  "#03045e"


        },
        secondary: {
            // main: "#047c7c", //1
            // main: "#4A9F8D", //2
            // main: "#007e65",
            // main: "#53D980",
            // main: "#FCCA46",
            main: "#2475ea",

            // light: "#00B498"
            // light: "#f5ffff",
            // dark: "#0E6647",
           // icon-background-light:"#DBE8E4"
        }

    }),
    shape: {
        borderRadius: 4
    },
    components: {
        MuiList:{
            defaultProps: {
                disablePadding: true
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            
                
            },
            styleOverrides: {
                disableElevation: true,
                root: {
                    textTransform: "none",
                    borderRadius: "16px"
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                draggable: true,
                elevation: 0,
            },
            styleOverrides: {
                root: {

                }
            }
        }
    }

})

