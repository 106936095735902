// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from '@firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2K33IwYOl5lQOXCB40uuPLCuiOx-0y2o",
  authDomain: "knymbus-k2022.firebaseapp.com",
  projectId: "knymbus-k2022",
  storageBucket: "knymbus-k2022.appspot.com",
  messagingSenderId: "416188951163",
  appId: "1:416188951163:web:a2708abb3525852241d0de",
  measurementId: "G-5RRC6EYWCS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebase = getFirestore(app)
export default firebase



