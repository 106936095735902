import { Box, SxProps } from "@mui/material"
import { ReactNode, useEffect, useState } from "react"
import { theme } from "../globals/theme"


type Family = "Fira Sans Condensed" | "Palanquin" | "Fira Sans Extra Condensed" | "Montserrat"
type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | "bold"
type Heading = 1 | 2 | 3 | 4 | 5 | 6 | 7
type ThemeColors = "primary" | "secondary"

const FontFamily: any = {
    "Fira Sans Condensed": `'Fira Sans Condensed', sans-serif`,
    "Palanquin": `'Palanquin', sans-serif`,
    "Fira Sans Extra Condensed": `'Fira Sans Extra Condensed', sans-serif`,
    "Montserrat": `'Montserrat', sans-serif`
}

const FontHeading: any = {
    1: "3.5em",
    2: "3.0em",
    3: "2.9em",
    4: "1.7em",
    5: "2.5em",
    6: "2.1em",
    7: "2.7em"
}

const FontColor: any  = {
    primary: {color: theme.palette.primary.main},
    secondary: {color: theme.palette.secondary.main}
}

interface IProps {
    children?: ReactNode;
    family?: Family;
    size?: string;
    fontWeight?: FontWeight;
    heading?: Heading;
    sx?: SxProps
    color?: ThemeColors
}

const Typography: React.FC<IProps> = ({ children, heading, family, sx, color }) => {

    const [fFamily, setFamily] = useState<Family>(FontFamily["Fira Sans Condensed"])
    const [weight, setWeight] = useState<FontWeight>(400)
    const [fHeading, setHeading] = useState<any>(FontHeading[3])
    const [fColor, setFontColor] = useState<any>({})

    useEffect(() => {
        if (heading) setHeading(FontHeading[heading])

        if (heading === undefined) setHeading({})

    }, [heading]);

    useEffect(() => {
        if (family) setFamily(FontFamily[family])

    }, [family]);

    useEffect(() => {
        if (color) setFontColor(FontColor[color])

    }, [color]);

    return (
        <Box sx={{ fontFamily: fFamily, fontWeight: weight, fontSize: fHeading, ...sx, ...fColor }}>{children}</Box>
    )
}

export default Typography