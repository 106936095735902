import { Box, Stack, TextField, Button, experimental_sx as sx, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";



const Wrapper = styled(Box)(sx({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const SignInForm  = ()=>{
    const navigate = useNavigate()
    const { handleSubmit, reset, control } = useForm();
    const onSubmit = (data: any) => {
        console.log(data);
        navigate("/http://localhost:3001/dashboard")
    }

    return (
        <Wrapper>
            <Box sx={{background: "#fff", width: "100%", padding: "24px", color: grey[900]}}>
                <Box >
                    <form>
                        <Stack gap={2}>
                        <Controller
                            name={"name"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField onChange={onChange} value={value} label={"Email"} placeholder="name@community-email.com" />
                            )}
                        />
                        <Controller
                            name={"password"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField onChange={onChange} value={value} label={"Password"} />
                            )}
                        />
                        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{height: "45px"}}>Sign in</Button>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default SignInForm