import { Box, Grid, Stack } from "@mui/material"
import DemoForm from "./DemoForm"

const RequestDemoComponent = () => {
    return (
        <Grid container spacing={3} sx={{
            margin: "150px 0", fontSize: "1.2em", lineHeight: "2em",
            "@media (max-width: 750px)": {
                margin: "50px 0",
                width: "100%",
                padding: "0 16px 50px 0"
            }
        }}>
            <Grid item xs={12} md={6}>
                <Stack gap={2}>
                    <Box sx={{ color: "#f3f1f5", fontSize: "1.9em", fontWeight: 600, lineHeight: "1.3em" }}>
                        Request a product
                        demo to see what
                        Straclou can do
                        for you.
                    </Box>

                    <Box sx={{ color: "#fff", fontSize: "1em" }}>
                        <Stack gap={5}>
                            <Box>If you are curious to see how Straclou can work for you
                                and your community, we would love to talk.</Box>
                            <Box>Fill out the form on the right to get started on your
                                journey with us to show you how Straclou can  be
                                integrated into your community. </Box>
                            <Box>We look forward to working with you.</Box>
                        </Stack>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <DemoForm />
            </Grid>

        </Grid>
    )
}

export default RequestDemoComponent