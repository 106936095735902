import firebase from "../firestore";
import { setDoc, getDoc, addDoc, doc, deleteDoc, collection, onSnapshot } from "@firebase/firestore"
import ENV from "./ENV";
import firestore from "../firestore";




export module API{
    // Tables
    const contactTable =  `contact-demo-form-straclou-${ENV}`

    // collection Refs
    const fireRef = collection(firestore,contactTable)

    // Methods
    export const set = async(obj: any)=>{
        // console.log("contact to add: ",obj)
            const nContact  = await addDoc(fireRef, obj)
            // console.log("new contact Id: ",nContact.id)
            update({cid: nContact.id},nContact.id)
            return nContact
    }

    export const update = async(updateObject: any, docId?: string,) => {
        if (docId !== undefined && docId !== null) {
            const gameRef = doc(firestore, contactTable, docId)
            setDoc(gameRef, updateObject, { merge: true })
        }
    }

}

