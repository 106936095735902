import { styled, experimental_sx as sx } from "@mui/material"
import { grey, red } from "@mui/material/colors"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import Typography from "../../components/Typography"

type Swing = "row" | "row-reverse"
interface IProps {
    item?: any
    position?: "odd" | "even"
}

const Feature = styled(Box)<{ swing: Swing }>(({ swing }) => sx({
    width: "400px",
    height: "300px",
    margin: "0px",
    padding: "8px",
    // background: grey[700],
    transition: "all 500ms ease-in-out",
    fontFamily: `'Montserrat', sans-serif`,
    display: "flex",
    // gridTemplateColumns: "1fr 1fr",
    justifyContent: "space-evenly",
    flexDirection: swing,




    "&:hover": {
        // transform: "scale(1.1)",
        // boxShadow: "2px 7px 26px 1px rgba(0,0,0,0.12)",
        // "-webkit-box-shadow": "2px 7px 26px 1px rgba(0,0,0,0.12)"
    },

    "@media (max-width: 750px)": {
        minWidth: "250px",
        width: "auto",

        "&:hover": {
            transform: "scale(1)",
            boxShadow: "none"
        }

    },


}))
const IconImg = styled(Box)(({ theme }) => sx({
    // background: red[600],
    color: "red",
    height: "50px",
    width: "50px",
    borderRadius: "5%",
    // border: `5px solid ${theme.palette.secondary.main}`
}))
const Title = styled(Box)(({ theme }) => sx({
    // background: grey[200],
    fontSize: "1.3em",
    marginTop: "0px",
    fontWeight: 600,
    color: theme.palette.primary.main

}))
const SubTitle = styled(Box)(sx({
    // background: grey[200],
    fontSize: ".9em",
    margin: "0 0 16px 0",
    color: grey[600]
}))
const Content = styled(Box)(sx({
    // background: grey[200],
    fontSize: "1.1em",
    lineHeight: "1.6em",
    marginLeft: "58px"
}))


const FeatureBenefitsItem: React.FC<IProps> = ({ item, position }) => {
    const [swing, setSwing] = useState<Swing>("row")

    useEffect(() => {
        if (position && position === "odd") setSwing("row-reverse"); else setSwing("row")
    }, [position])
    return (
        <Feature swing={swing}>
            <Box sx={{ background: "0", width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                <Box >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                            // background: "red",
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            marginRight: "8px",


                        }}>
                            <IconImg sx={{ backgroundImage: `url(${item.icon})`, backgroundSize: "cover", color: "red" }} />
                        </Box>

                        <Box sx={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>

                            <Title>{item.title}</Title>
                        </Box>
                    </Box>
                    <Content>
                        <Typography family="Palanquin" sx={{

                            "@media (max-width: 750px)": {
                                fontSize: "16pt"
                            }
                        }}>
                            {item?.content}
                        </Typography>
                    </Content>
                </Box>
            </Box>
        </Feature >
    )
}

export default FeatureBenefitsItem