import { RouteObject, Navigate } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import SignInPage from "./Pages/Authenication/SignInPage";
import HomePage from "./Pages/HomePage/HomePage";
import LearnMoreDemo from "./Pages/LearnMoreDemo/LearnMoreDemo";

const useAppRoutes = () => {
    const routes: RouteObject[] = [
        {
            path: '/',
            index: true,
            element: <HomePage/>
        },
        {
            path:'learn_more_demo',
            element: <LearnMoreDemo />
        },
        {
            path: "*",
            element: <PageNotFound />
        },
        {
            path: "signin",
            element: <SignInPage/>
        }
    ]
    return [routes]
}

export default useAppRoutes