import { Box, styled, experimental_sx as sx } from "@mui/material"
import { grey } from "@mui/material/colors"
import { theme } from "../../globals/theme"

const Wrapper = styled(Box)(sx({
    background: theme.palette.secondary.dark, 
    height: "15vh", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "flex-end", 
    padding:"0 0 18px 0",
    fontSize: ".92em",
    color: grey[50]
    
}))
const Footer = ()=>{
    return (
        <Wrapper>Copyright © 2023 Knymbus.  All rights reserved.</Wrapper>
    )
}

export default Footer