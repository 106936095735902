
import { Box, experimental_sx as sx, Stack, styled, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import config from "../../globals/demo-config";
import ErrorHelper from "../../components/ErrorHelper";
import { LoadingButton } from '@mui/lab';
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Wrapper = styled(Box)(({ theme }) => sx({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.dark
}))

type FormInputs = {
    name: string
    email: string
    communityName: string
    phone: string
    unitCount: number

}
const DemoForm = () => {

    const { handleSubmit, reset, control } = useForm<FormInputs>();


    const FormErrorMessages: any = {
        name: ["Name is required"],
        communityName: ["Community name is required"],
        unitCount: [
            "Please provide the number of units you intend to manage",
            "Units cannot have a value less then 1"
        ],
        email: ['Please provide an email address'],
        phone: ["Please provide a phone number"]
    }

    const [formErrors, setFormErrors] = useState<any>({})
    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: FormInputs) => {

        try {
            setLoading(true)

            let valid = validateForm(data)

            if (!valid) throw new Error("Invalid form");

            /**
             * Community Name 
             */
            config.communityName = data.communityName
            /**
             * Contact Email
             */
            config.recipient = data.email
            /**
             * Contact Phone
             */
            config.phone = data.phone
            /**
             * Community Size
             */
            config.communitySize = data.unitCount
            /**
             * Contact Name
             * Split name to first and last 
             */
            config.firstName = data.name.split(" ")[0]
            config.lastName = data.name.split(" ")[1]

            let res = await config.send()

            reset()
            toast.success("Your request was successfully sent to the Straclou team.", {
                autoClose: 5000,
                pauseOnHover: true,
                draggable: true,

            })
            setLoading(false)

        } catch (error: any) {
            if (error['message'] !== "Invalid form") {
                toast.error("There was an error when sending your request to the Straclou team.", {
                    autoClose: 5000,
                    pauseOnHover: true,
                    draggable: true,

                })
            }
            setLoading(false)
        }


    }

    const validateForm = (data: any) => {
        let state: boolean = true
        let errors: any = {}

        Object.keys(data).forEach((key: string) => {

            if (data[key].length < 1) {
                state = false
                errors[key] = FormErrorMessages[key][0]
            }

            if (key === "unitCount" && data[key].length > 0) {
                if (data[key] < 1) {
                    errors[key] = FormErrorMessages[key][1]
                    state = false
                }
            }

        })
        setFormErrors({ ...errors })
        return state
    }






    return (
        <Wrapper>

            <ToastContainer style={{ fontSize: "13pt", lineHeight: "22px" }} />
            <Box sx={{ background: "#fff", width: "100%", padding: "24px", color: grey[900] }}>
                <Box sx={{ fontSize: "1.4em", fontWeight: 100, padding: "16px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>Let’s help you book a demo</Box>
                <Box >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack gap={3}>
                            <Box >
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value, ref } }) => (
                                        <TextField
                                            ref={ref}
                                            fullWidth
                                            onChange={onChange}
                                            value={value}
                                            label={"Name"}
                                            placeholder="Enter contact name"
                                        />
                                    )}
                                />
                                <ErrorHelper errors={formErrors} name="name" />


                            </Box>

                            <Box >
                                <Controller
                                    name="communityName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField fullWidth onChange={onChange} value={value} label={"Name of community"} />
                                    )}
                                />
                                <ErrorHelper errors={formErrors} name="communityName" />
                            </Box >

                            <Box >
                                <Controller
                                    name="unitCount"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField fullWidth type="number" onChange={onChange} value={value} label={"How many units do you currently manage?"} />

                                    )}
                                />
                                <ErrorHelper errors={formErrors} name="unitCount" />
                            </Box >

                            <Box >
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField fullWidth type="email" onChange={onChange} value={value} label={"Email address"} />
                                    )}
                                />
                                <ErrorHelper errors={formErrors} name="email" />
                            </Box >

                            <Box >
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue="+1 876"
                                    rules={{
                                        validate: matchIsValidTel
                                    }}
                                    render={({ field, fieldState }) => (
                                        <MuiTelInput
                                            {...field} fullWidth
                                            label={"Contact number"}
                                            helperText={fieldState.error ? "Telephone number is invalid" : ""}
                                            error={fieldState.invalid}
                                        />
                                    )}
                                />
                                <ErrorHelper errors={formErrors} name="phone" />
                            </Box >
                            <LoadingButton loading={isLoading} variant="contained" color="secondary" type="submit" sx={{ height: "45px" }}>Book demo</LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default DemoForm