import { Box, styled } from "@mui/material"
import { red } from "@mui/material/colors"
import { useEffect, useState } from "react"

const Wrapper = styled(Box)(({ theme }) => ({
    color: red[300],
    fontSize: "10pt",
    marginTop: "-8px"
}))

export default function ErrorHelper(props: any) {
    const { errors, name } = props
    const [message, setMessage] = useState<string>("")

    useEffect(() => {
        if (errors !== undefined && typeof errors === "object") {
            if (errors.hasOwnProperty(name)) {
                setMessage(errors[name])
            } else {
                setMessage("")
            }

        } else {
            setMessage("")
        }
    }, [errors])
    return (
        <Wrapper>{message}</Wrapper>
    )
}