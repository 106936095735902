import { useEffect, useState } from "react"


export default function useAuth() {
    const [isAuth, setAuth] = useState<boolean>(false)
    const [user, setUser] = useState<any>({})

    useEffect(() => {
        // Auth.currentUserInfo().then(res => {
        //     if(res){
        //         setUser({
        //             email: res["attributes"]['email'],
        //             name: res['attributes']['name'],
        //             uid: res['attributes']['sub']
        //         })    
        //         setAuth(true)
        //     }
        // })
    }, [])


    const logout = async()=>{
        console.log("user logged out")
        // Auth.signOut()
    }
    return {
        isAuth,
        user,
        logout
    }
}